/* eslint-disable sort-keys */
import React, { useEffect, useRef, useState } from 'react';

interface ITrendingNFTsSlide {
	id: string;
	symbol: string;
	imgUrl: string;
	value: string;
	name: string;
	createdAt: Date;
	network: string;
	createdByAddress: string;
	listId: string;
}

const TrendingNFTs = () => {
	const [trendingNFTs, setTrendingNFTs] = useState<ITrendingNFTsSlide[]>([]);

	const sliderRef = useRef<HTMLDivElement>(null);

	const handleOnSlide = (direction: number) => {
		if (sliderRef.current) {
			const sliderList = sliderRef.current;
			const scrollAmount = sliderList.clientWidth * direction;
			sliderList.scrollBy({ behavior: 'smooth', left: scrollAmount });
		}
	};

	useEffect(() => {
		const fetchTrendingNFTs = async () => {
			const response = await fetch('https://app.blobscriptions.xyz/api/v1/tokens/nft/trending?_page=1&_limit=10');
			const data = (await response.json()) as { data: ITrendingNFTsSlide[] };
			setTrendingNFTs(data.data);
		};

		fetchTrendingNFTs();
	}, []);

	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px]'>Trending NFTs</p>

			<div className='w-[100vw] max-w-full relative'>
				<div
					className='overflow-x-auto [&::-webkit-scrollbar]:hidden'
					ref={sliderRef}
				>
					<div
						className='mt-12 slider-list'
						style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(${trendingNFTs.length},1fr)` }}
					>
						<button
							onClick={() => handleOnSlide(1)}
							className='flex items-center justify-center bg-[#363338] sm:w-[75px] sm:h-[75px] w-[50px] h-[50px] rounded-full drop-shadow-[0px_6px_6.7px_rgba(0,0,0,0.57)] absolute -right-8 top-[50%] -translate-y-1/2 z-10'
						>
							<img
								src='Expand_right.png'
								className='sm:h-[32px] sm:w-[32px] h-[24px] w-[24px]'
							/>
						</button>
						{trendingNFTs.map((item, i) => {
							const imageType = item.imgUrl.split('.').pop();
							const link = `https://app.blobscriptions.xyz/${item.network}/nft/${item.symbol}`;
							return (
								<div
									className='md:w-[360px] md:h-[360px] sm:w-[300px] sm:h-[300px] w-[250px] h-[250px] relative'
									key={i}
								>
									<a
										href={link}
										target='_blank'
										rel='noreferrer'
									>
										{imageType === 'mp4' ? (
											<video
												autoPlay
												muted
												loop
												src={item.imgUrl}
												className='rounded-lg absolute top-0 left-0 w-full h-full object-cover'
											/>
										) : (
											<img
												src={item.imgUrl}
												className='rounded-lg absolute top-0 left-0 w-full h-full object-cover'
												alt={item.name}
											/>
										)}
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrendingNFTs;
